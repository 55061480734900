import { Link, } from "react-router-dom";
import { Image } from "@mantine/core";
import { useEffect } from "react";
import { LocationMarkerIcon } from "@heroicons/react/solid";


export const City = ({ city }) => {

    console.log(city)

    return (
        <div>

        </div>
    )
}
